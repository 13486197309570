import React from 'react';
import styled from 'styled-components';
import { Container, Vector } from '@fragments';
import { spaceMb, spaceDt, screenMin, screenMax, color, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const SliderContainer = styled(Container)`
  ${screenMin('lg')} {
    flex-direction: column;
    margin-left: ${spaceDt(15)};
    margin-top: ${spaceDt(13)};
  }
  ${screenMax('lg')} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SliderInnerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  ${screenMax('lg')} {
    flex-direction: column;
  }
`;

export const SliderContent = styled.div`
  pointer-events: none;
  ${screenMin('lg')} {
    width: ${toVW(972, 'desktop')};
    height: ${toVW(534, 'desktop')};
  }
  ${screenMax('lg')} {
    width: 100%;
  }
`;

export const SliderControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${screenMax('lg')} {
    position: absolute;
    flex-direction: row-reverse;
    justify-content: space-between;
    z-index: 100;
    width: 100%;
    top: ${toVW(115.5, 'mobile')};
  }
`;

export const SliderArrow = styled((props) => <Vector {...props} />)`
  width: 100%;
  height: 100%;
`;

export const SliderArrowButton = styled.button`
  width: ${toVW(48, 'desktop')};
  height: ${toVW(48, 'desktop')};
  margin: ${spaceDt(3)};
  padding: 0;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background: none;
  cursor: pointer;

  ${screenMax('lg')} {
    width: ${toVW(48, 'mobile')};
    height: ${toVW(48, 'mobile')};
  }
`;

export const Slide = styled.div`
  ${screenMin('lg')} {
    width: ${toVW(972, 'desktop')};
    height: ${toVW(534, 'desktop')};
  }
`;

export const SlideDots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${spaceDt(5)};
  ${screenMax('lg')} {
    margin-left: ${spaceMb(2)};
  }
`;

export const SlideDot = styled.div`
  width: ${(props) => (props.active ? toVW(30, 'desktop') : toVW(5, 'desktop'))};
  height: ${toVW(5, 'desktop')};
  margin-right: ${spaceDt(1)};
  border-radius: ${(props) => (props.active ? '30%' : '50%')};
  background-color: ${color.text.secondary};
  ${screenMax('lg')} {
    width: ${(props) => (props.active ? toVW(30, 'mobile') : toVW(5, 'mobile'))};
    height: ${toVW(5, 'mobile')};
    margin-right: ${spaceMb(1)};
  }
`;

export const SlideDotText = styled.div`
  color: ${color.text.secondary};
  ${getTypography('body-2')};
`;
