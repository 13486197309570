import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SliderCore from 'infinite-react-carousel';
import {
  SliderContainer,
  SliderInnerContainer,
  SliderContent,
  SliderControls,
  SliderArrow,
  SliderArrowButton,
  Slide,
  SlideDots,
  SlideDot,
  SlideDotText,
} from './Slider.styled';

export const Slider = ({ className, slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef();
  const slidePrev = () => {
    setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
    sliderRef.current.slickPrev();
  };
  const slideNext = () => {
    setCurrentIndex((currentIndex + 1) % slides.length);
    sliderRef.current.slickNext();
  };

  return (
    <SliderContainer className={className}>
      <SliderInnerContainer>
        <SliderControls>
          <SliderArrowButton onClick={slideNext}>
            <SliderArrow name="icon-arrow-round--right" />
          </SliderArrowButton>
          <SliderArrowButton onClick={slidePrev}>
            <SliderArrow name="icon-arrow-round--left" />
          </SliderArrowButton>
        </SliderControls>
        <SliderContent>
          <SliderCore ref={sliderRef} arrows={false}>
            {slides.map((component, index) => (
              <Slide key={index}>{component}</Slide>
            ))}
          </SliderCore>
        </SliderContent>
      </SliderInnerContainer>
      <SlideDots>
        {slides.map((_, index) => (
          <SlideDot key={index} active={index === currentIndex} />
        ))}
        <SlideDotText>
          {currentIndex + 1} of {slides.length}
        </SlideDotText>
      </SlideDots>
    </SliderContainer>
  );
};

Slider.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};
