import React from 'react';
import PropTypes from 'prop-types';
import { useStateScreenMobile } from '@helpers/hooks';
import { TYPE_COMPONENT } from '@helpers/types';
import {
  SectionContainer,
  SectionVector,
  SectionWrapper,
  SectionName,
  SectionTitle,
  SectionContent,
  SectionContentWrapper,
} from './SlideContent.styled';

export const SlideContent = ({ className, children, name, title, description }) => {
  /**
   * At the moment, this section is used in two places, Case Study page and Software Development page
   * Both pages have slightly different design inside SectionSplit. For now it is required to use "compact"
   * indicator to match Software Development page design requirements.
   * TODO: Find out if there is a better solution than passing props to styled components
   */
  return (
    <SectionContainer className={className}>
      {useStateScreenMobile() && <SectionTitle>{title}</SectionTitle>}
      {useStateScreenMobile() && (
        <SectionWrapper>
          <SectionVector>{children}</SectionVector>
        </SectionWrapper>
      )}
      <SectionWrapper>
        <SectionContentWrapper>
          {!useStateScreenMobile() && (
            <div>
              <SectionName>{name}</SectionName>
              <SectionTitle>{title}</SectionTitle>
            </div>
          )}

          <SectionContent>{description}</SectionContent>
        </SectionContentWrapper>
      </SectionWrapper>
      {!useStateScreenMobile() && (
        <SectionWrapper>
          <SectionVector>{children}</SectionVector>
        </SectionWrapper>
      )}
    </SectionContainer>
  );
};

SlideContent.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: TYPE_COMPONENT.isRequired,
};
