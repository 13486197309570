import React from 'react';
import { Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { NumberContainer } from './SectionNumbers.styled';
import { NumberPad } from '../components';
import dataTeam from '../Team.data';

export const SectionNumbers = () => {
  const numberComponents = dataTeam.sectionNumbers.map((item, index) => {
    const { number, description, mobileHeight, mobileTop, desktopHeight, desktopTop } = item;

    return (
      <NumberPad
        number={number}
        description={description}
        mobileHeight={mobileHeight}
        mobileTop={mobileTop}
        desktopHeight={desktopHeight}
        desktopTop={desktopTop}
        key={index}
      />
    );
  });

  return (
    <StringsWrapper>
      {!useStateScreenMobile() && <Strings name="team-numbers" height={1440} top={-535} />}
      <NumberContainer>{numberComponents}</NumberContainer>
    </StringsWrapper>
  );
};
