import React, { useEffect, useMemo, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SliderOffcanvas, ContentBlock } from '@fragments';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStateScreenMobile } from '@helpers/hooks';

import {
  SectionTeamCultureContainer,
  SliderContainer,
  SlideContainer,
  SlideSquare,
  SlideRect,
} from './SectionTeamCulture.styled';
import dataTeam from '../Team.data';

export const SectionTeamCulture = () => {
  const isMobileScreen = useStateScreenMobile();
  const [slides, setSlides] = useState([]);
  const {
    cultureSquareFiles: { nodes: squareImages },
    cultureRectFiles: { nodes: rectImages },
  } = useStaticQuery(graphql`
    query {
      cultureSquareFiles: allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "team/culture" } }
      ) {
        nodes {
          id
          publicURL
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO, width: 1422, height: 1485)
          }
        }
      }
      cultureRectFiles: allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "team/culture" } }
      ) {
        nodes {
          id
          publicURL
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO, height: 1485, width: 1968)
          }
        }
      }
    }
  `);

  /* eslint-disable react/jsx-key */
  const mobileSlides = useMemo(
    () =>
      squareImages.map(({ childImageSharp: { gatsbyImageData }, name }) => (
        <GatsbyImage image={gatsbyImageData} alt={name} />
      )),
    []
  );

  const imagePairs = useMemo(() => {
    const breakpoint = Math.round(squareImages.length / 2);
    const collectionSquares = squareImages.slice(0, breakpoint);
    const collectionRectangulars = rectImages.slice(breakpoint);

    return [
      ...collectionSquares.map(({ childImageSharp: { gatsbyImageData }, name }) => {
        const rectImage = collectionRectangulars.shift();

        return [
          <GatsbyImage image={gatsbyImageData} alt={name} />,
          ...(rectImage?.childImageSharp?.gatsbyImageData
            ? [<GatsbyImage image={rectImage.childImageSharp.gatsbyImageData} alt={rectImage.name} />]
            : []),
        ];
      }),
      ...collectionRectangulars.map(({ childImageSharp: { gatsbyImageData: rectGatsbyImageData } }) => [
        <GatsbyImage image={rectGatsbyImageData} />,
      ]),
    ];
  }, []);

  const desktopSlides = useMemo(
    () =>
      imagePairs.map(([image1, image2]) => {
        return image2 ? (
          <SlideContainer>
            <SlideSquare>{image1}</SlideSquare>
            <SlideRect>{image2}</SlideRect>
          </SlideContainer>
        ) : (
          <SlideContainer>{image1}</SlideContainer>
        );
      }),
    []
  );
  /* eslint-disable react/jsx-key */

  useEffect(() => setSlides(isMobileScreen ? mobileSlides : desktopSlides), [isMobileScreen]);

  return (
    <>
      <SectionTeamCultureContainer>
        <ContentBlock
          headline={dataTeam.sectionTeamCultures.title}
          content={dataTeam.sectionTeamCultures.description}
        />
      </SectionTeamCultureContainer>

      {slides.length > 0 && (
        <SliderContainer>
          <SliderOffcanvas slides={slides} />
        </SliderContainer>
      )}
    </>
  );
};
