import styled from 'styled-components';
import { screenMax, spaceDt, spaceMb } from '@helpers/styles';

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spaceDt(23)};

  ${screenMax('lg')} {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: ${spaceMb(2)};
    margin-top: ${spaceMb(7)};
  }
`;
