import React from 'react';
import { PageHeader } from '@fragments';
import dataTeam from '../Team.data';

const {
  sectionHeader: { name, headline, content, ctaObj, linkArray },
} = dataTeam;

export const SectionHeader = () => (
  <PageHeader name={name} headline={headline} content={content} ctaObj={ctaObj} linkArray={linkArray} />
);
