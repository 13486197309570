import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStateScreenMobile } from '@helpers/hooks';
import {
  Container,
  Name,
  Details,
  DetailsOutline,
  Location,
  LinkContainer,
  ButtonLink,
  Wrapper,
} from './OpenPosition.styled';

export const OpenPosition = ({ name, location, details, salaryMin, salaryMax, url, isLast }) => {
  const isMobileScreen = useStateScreenMobile();
  const viewJobOffer = (to) => {
    window.open(to, '_blank');
  };
  const handleViewJobOfferClick = useCallback(() => viewJobOffer(url), [url, viewJobOffer]);

  const salary = useMemo(
    () =>
      !!salaryMin && !!salaryMax ? (
        <div>{`${salaryMin} - ${salaryMax} PLN net`}</div>
      ) : (
        <div>{`${salaryMin} PLN net`}</div>
      ),
    [salaryMin, salaryMax]
  );

  return (
    <Container isLast={isLast}>
      <Wrapper>
        <Name>{name}</Name>
        <Details>
          {!isMobileScreen && <DetailsOutline>{details}</DetailsOutline>}
          {salary}
        </Details>
        <Location>{location}</Location>
        <LinkContainer>
          <ButtonLink onClick={handleViewJobOfferClick}>See job offer</ButtonLink>
        </LinkContainer>
      </Wrapper>

      {!!name && isMobileScreen && <DetailsOutline>{details}</DetailsOutline>}
    </Container>
  );
};

OpenPosition.propTypes = {
  name: PropTypes.string.isRequired,
  salaryMin: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  details: PropTypes.string.isRequired,
  salaryMax: PropTypes.number,
};

OpenPosition.defaultProps = {
  isLast: false,
  salaryMax: null,
};
