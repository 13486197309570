import React from 'react';

import { ContentBlock } from '@fragments';
import { SectionCoreValuesContainer } from './SectionCoreValues.styled';
import dataTeam from '../Team.data';
import { SlideContent, Slider } from '../components';

export const SectionCoreValues = () => {
  const slides = dataTeam.sectionCoreValues.coreValues.map((item, index) => {
    const { illustration, title, description } = item;

    return (
      <SlideContent name="Core Values" title={title} description={description} key={index}>
        {illustration}
      </SlideContent>
    );
  });
  return (
    <div>
      <SectionCoreValuesContainer>
        <ContentBlock headline={dataTeam.sectionCoreValues.title} content={dataTeam.sectionCoreValues.description} />
      </SectionCoreValuesContainer>
      <Slider slides={slides} />
    </div>
  );
};
