import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionSplitPeople, SectionPeopleWrapper, PeopleImageContainer } from './SectionPeople.styled';
import dataTeam from '../Team.data';

const { mainSection } = dataTeam.sectionPeople;

export const SectionPeople = () => {
  const isMobileScreen = useStateScreenMobile();

  // Query might brake if image is changed. For some reason, single file query didn't want to work as expected.
  const {
    gif,
    images: {
      nodes: [teamImage],
    },
  } = useStaticQuery(graphql`
    query {
      gif: file(relativePath: { eq: "team/gif/team.gif" }) {
        id
        publicURL
        name
      }
      images: allFile(filter: { relativePath: { eq: "team/gif/team.jpg" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, formats: AUTO)
          }
        }
      }
    }
  `);

  const image = isMobileScreen ? (
    <GatsbyImage image={teamImage.childImageSharp.gatsbyImageData} alt="People of Ragnarson" />
  ) : (
    <img src={gif.publicURL} alt="People of Ragnarson" />
  );

  return (
    <SectionPeopleWrapper>
      <SectionSplitPeople
        compact
        vectorName="none"
        name={mainSection.name}
        title={mainSection.title}
        content={mainSection.content}
        contentAside={<PeopleImageContainer>{image}</PeopleImageContainer>}
      />
    </SectionPeopleWrapper>
  );
};
