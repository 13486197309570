import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { color, spaceMb, spaceDt, getFontSet, screenMin, screenMax } from '@helpers/styles';

const NumberPadContainer = styled.div`
  position: relative;

  ${screenMax('lg')} {
    &:nth-child(odd) {
      margin-right: ${spaceMb(2.5)};
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-child(5) {
      margin-top: ${spaceMb(5)};
    }

    padding-left: ${toVW(3, 'mobile')};
    width: ${toVW(155, 'mobile')};
    height: ${toVW(115, 'mobile')};
  }

  ${screenMin('lg')} {
    width: ${toVW(180, 'desktop')};
    margin-right: ${spaceDt(4)};
  }
`;

const NumberPadRectangle = styled.div`
  position: absolute;
  width: ${toVW(155, 'mobile')};
  height: ${(props) => toVW(props.mobileHeight, 'mobile')};
  top: ${(props) => spaceMb(props.mobileTop)};
  background-color: ${color.accent[1]};
  z-index: -1;

  ${screenMin('lg')} {
    width: ${toVW(180, 'desktop')};
    height: ${(props) => toVW(props.desktopHeight, 'desktop')};
    top: ${(props) => spaceDt(props.desktopTop)};
  }
`;

const NumberContent = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(2)};
  }

  ${getFontSet('heading-3', 'desktop', 'mobile')};

  ${screenMin('lg')} {
    ${getFontSet('heading-2', 'desktop')}
  }
`;

const NumberDescription = styled.div`
  ${getFontSet('heading-4', 'mobile')};
  color: ${color.text.secondary};

  ${screenMin('lg')} {
    ${getFontSet('body-2', 'desktop')};
  }
`;

export { NumberPadRectangle, NumberPadContainer, NumberContent, NumberDescription };
