import styled from 'styled-components';
import { spaceDt, screenMax, getTypography, spaceMb, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const Content = styled.div`
  height: 100%;
  width: ${toVW(600, 'desktop')};

  ${screenMax('lg')} {
    width: ${toVW(222, 'mobile')};
  }
`;

export const Description = styled.p`
  ${getTypography('body-2')};
  width: 100%;
  margin-bottom: ${spaceDt(4)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(5)};
  }
`;

export const Person = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PersonImage = styled.img`
  width: ${toVW(58, 'desktop')};
  height: ${toVW(58, 'desktop')};
  margin-right: ${spaceDt(1.5)};

  ${screenMax('lg')} {
    width: ${toVW(58, 'mobile')};
    height: ${toVW(58, 'mobile')};
    margin-right: ${spaceMb(1.5)};
  }
`;

export const PersonName = styled.div`
  ${getTypography('heading-4')};
`;

export const PersonRole = styled.div`
  color: ${color.text.secondary};
  ${getTypography('body-2')};
`;
