import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

export const SectionReferralContainer = styled(Container)`
  position: relative;
  align-items: center;
  padding-left: ${spaceMb(4)};
  padding-right: ${spaceMb(7)};
  min-width: ${toVW(294, 'mobile')};
  margin-top: ${spaceMb(9)};

  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: ${spaceDt(26.5)};
    padding-right: ${spaceDt(26.5)};
    margin-top: ${spaceDt(12.5)};
  }
`;

export const SectionTitle = styled.h2`
  ${getTypography('heading-2')};
  width: ${toVW(296, 'mobile')};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
  }

  ${screenMin('lg')} {
    width: ${toVW(469, 'desktop')};
  }
`;
