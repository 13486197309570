import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { color, screenMin, screenMax, getTypography, spaceDt, spaceMb } from '@helpers/styles';
import { Button } from '@fragments';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${color.text.primary};
  border-bottom: ${({ isLast }) => (isLast ? `1px solid ${color.text.primary}` : 'none')};
  padding: ${spaceMb(2)} var(--content-padding-right) ${spaceMb(2)} var(--content-padding-left);

  ${screenMin('lg')} {
    padding: ${spaceDt(3)} var(--content-padding-lg-right) ${spaceDt(3)} var(--content-padding-lg-left);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${screenMin('lg')} {
    flex-direction: row;
  }
`;

export const Name = styled.h3`
  margin-bottom: ${spaceMb(1.5)};

  ${screenMin('lg')} {
    width: ${toVW(288, 'desktop')};
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  ${getTypography('heading-3')};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;

  ${screenMin('lg')} {
    margin-bottom: 0;
    padding-left: ${toVW(105, 'desktop')};
  }

  ${getTypography('body-1')};
`;

export const DetailsOutline = styled.div`
  color: ${color.text.outline};

  ${screenMax('lg')} {
    writing-mode: tb;
    ${getTypography('heading-3')};
    max-height: ${toVW(135, 'mobile')};
    text-align: center;
  }
`;

export const Location = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-end;

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(2.5)};
  }
`;

export const LinkContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${screenMax('lg')} {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const ButtonLink = styled(Button)`
  ${getTypography('body-2')};
  padding: ${spaceMb(1.5)} ${spaceMb(6)};

  ${screenMin('lg')} {
    white-space: nowrap;
    padding: ${spaceDt(1.5)} ${spaceDt(6)};
  }
`;
