import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

export const SectionCoreValuesContainer = styled(Container)`
  align-items: center;
  padding-left: ${spaceMb(4)};
  padding-right: ${spaceMb(7)};
  min-width: ${toVW(270, 'mobile')};
  margin-top: ${spaceMb(5)};

  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: ${spaceDt(26.5)};
    padding-right: ${spaceDt(26.5)};
    margin-top: ${spaceDt(13.5)};
  }
`;
