import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

export const SectionTeamCultureContainer = styled(Container)`
  position: relative;
  align-items: center;
  padding-left: ${spaceMb(4)};
  padding-right: ${spaceMb(7)};
  min-width: ${toVW(294, 'mobile')};
  margin-top: ${spaceMb(2.5)};

  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: ${spaceDt(26.5)};
    padding-right: ${spaceDt(26.5)};
    margin-top: ${spaceDt(5)};
  }
`;

export const TeamCultureImage = styled.img`
  max-width: none;
  height: 100%;
`;

export const SliderContainer = styled(Container)`
  margin-top: ${spaceMb(4)};
  padding: 0;

  ${screenMin('lg')} {
    margin-top: ${spaceDt(10)};
    padding-left: ${spaceDt(18)};
  }
`;

export const SlideContainer = styled.div`
  display: flex;
`;

export const SlideSquare = styled.div`
  ${screenMin('lg')} {
    margin-right: ${spaceDt(1)};
    flex: 1 0 ${toVW(400, 'desktop')};
    max-height: ${toVW(480, 'desktop')};
  }
`;

export const SlideRect = styled.div`
  ${screenMin('lg')} {
    flex: 0 0 ${toVW(656, 'desktop')};
    max-height: ${toVW(480, 'desktop')};
  }
`;
