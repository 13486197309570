import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography } from '@helpers/styles';
import { SectionSplit, ContentBlock } from '@fragments';

export const PeopleImageContainer = styled.div`
  ${screenMin('lg')} {
    margin: ${spaceDt(20)} 0;
  }
`;

export const SectionSplitPeople = styled(SectionSplit)`
  flex-direction: row-reverse;

  ${screenMax('lg')} {
    flex-direction: column-reverse;
  }

  ${getTypography('heading-2')};
`;

export const SectionPeopleBottom = styled(ContentBlock)`
  ${screenMin('lg')} {
    padding-left: ${spaceDt(11)};
  }
`;

export const SectionPeopleWrapper = styled.div`
  position: relative;
  margin-top: ${spaceMb(-7)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(-5)};
  }
`;
