import React from 'react';
import { Link, TextEffect } from '@fragments';
/** FIXME: Import images over graphql and use gatsby-plugin-image */
import WorkStyle1 from '@images/WorkStyle1.png';
import WorkStyle2 from '@images/WorkStyle2.png';
import WorkStyle3 from '@images/WorkStyle3.png';

import { TreeGrowth, VikingShield, VikingPennant, VikingCup, VikingMoneyJar } from '@illustrations';

const dataTeam = {
  sectionHeader: {
    name: 'Team',
    headline: (
      <>
        A <TextEffect effect="highlight">great</TextEffect> workplace combines exceptional people and freedom to be
        awesome
      </>
    ),
    content: `We create a diverse environment where people are empowered to achieve their fullest potential.
              Here, everyone becomes a mini-CEO who makes independent decisions guided by the market context and the company's goals.
              Each decision we make is backed by teammates’ input - which is part of our feedback culture.
    `,
    ctaObj: {
      to: '#open-positions',
      label: 'See open positions',
      title: 'Go to criteria section',
    },
    linkArray: [
      {
        to: '/manifesto',
        label: 'Manifesto',
      },
    ],
  },
  sectionStartup: {
    callout: (
      <>
        Startups <br /> we <br /> seek
      </>
    ),
    list: [
      {
        blockContent: 'Ideally, you are an early-stage startup with an MVP or pre-product.',
      },
      {
        blockContent: 'Creating a positive impact is as important as becoming a profitable organisation.',
      },
      {
        blockContent: 'Software is an important part of your solution.',
      },
      {
        blockContent: 'You’re looking to raise a pre-seed round.',
      },
    ],
  },
  sectionStatement: {
    content: (
      <>
        <TextEffect effect="highlight">If you meet</TextEffect>
        our criteria, contact us via form below with a link to your pitch deck and a brief introduction.
      </>
    ),
  },
  contactFormHeader: 'Apply',
  sectionPeople: {
    mainSection: {
      name: 'RAGNARSON',
      title: 'Get to know our people',
      content: `We are a unique combination of talented teammates from a wide range of backgrounds and experiences.
      Among us, you’ll find tech geeks, travelers, foodies, athletes, hikers, yogis, musicians, scuba-divers, carpenters, and even a pilot.
      What brings us together is our commitment to creating software that will impact our natural environment and communities.`,
    },
  },
  sectionNumbers: [
    {
      number: '35',
      description: 'Team members',
      mobileHeight: 100,
      mobileTop: -28,
      desktopHeight: 100,
      desktopTop: 43,
    },
    {
      number: '4/17',
      description: 'Time Zones/Cities',
      mobileHeight: 100,
      mobileTop: 12,
      desktopHeight: 150,
      desktopTop: -35,
    },
    {
      number: '155',
      description: 'Combined years in software development',
      mobileHeight: 70,
      mobileTop: 27,
      desktopHeight: 55,
      desktopTop: 3,
    },
    {
      number: '10',
      description: 'Parents',
      mobileHeight: 100,
      mobileTop: -4,
      desktopHeight: 150,
      desktopTop: 43,
    },
    {
      number: '179',
      description: 'Custom emoji on Slack',
      mobileHeight: 100,
      mobileTop: -4,
      desktopHeight: 150,
      desktopTop: 43,
    },
  ],
  sectionCoreValues: {
    title: 'United by our core values',
    description: `Our core values are an active part of everything we do.
                  Each one reminds us to stay true to ourselves while making conscious
                  decisions and driving amazing outcomes for our clients.`,
    coreValues: [
      {
        illustration: (
          <>
            <TreeGrowth className="slide-vector" />
          </>
        ),
        title: 'Growth',
        description: `Eagerly learn new things. Have broad interests and be curious about the world.
                      Strive for perfection. Share your knowledge with others.`,
      },
      {
        illustration: (
          <>
            <VikingShield className="slide-vector" />
          </>
        ),
        title: 'Responsibility',
        description: `Take on uncomfortable tasks that you can handle.
                      Don’t be afraid of the risk associated with decision-making.
                      Don’t run away from mistakes. Quickly communicate problems.`,
      },
      {
        illustration: (
          <>
            <VikingPennant className="slide-vector" />
          </>
        ),
        title: 'Commitment',
        description: `Take initiative. Care about the success of the team.
                      Go beyond what is expected. Find passion in what you do.`,
      },
      {
        illustration: (
          <>
            <VikingCup className="slide-vector" />
          </>
        ),
        title: 'Openness',
        description: `Be brave and question decisions that you don’t agree with. Openly talk about problems.
                      Dedicate time to helping others. Approach change positively.`,
      },
      {
        illustration: (
          <>
            <VikingMoneyJar className="slide-vector" />
          </>
        ),
        title: 'Delivering value',
        description: `Recognize what has to be done well from the very beginning.
                      Make complex things simple and know how to choose 20% of work that provides 80% of the results.
                      Deliver high-quality solutions while keeping the big picture in mind.`,
      },
    ],
  },
  sectionMisson: {
    name: 'Misson',
    title: 'Empowering companies to positively influence their business, social, and natural environment.',
    description: (
      <>
        {`We are a technological and financial partner for entrepreneurs who strive to improve sustainability,
        fight climate change or reduce socio-economic inequalities. As a partner, we want to deeply
        understand the business and help with making conscious software decisions.
        We devote our skills and expertise to fulfill customers’ vision of a better future.
        This is `}
        <Link to="/manifesto" title="our manifesto" decoration="underline" typography="body-1">
          our manifesto
        </Link>
        .
      </>
    ),
  },
  sectionWorkStyle: [
    {
      name: 'Agnieszka Kopiczko',
      role: 'Project Manager',
      image: WorkStyle1,
      description: `Hiring is a two way conversation – you’re interviewing us as well.
      Throughout the process, you’ll get a real insight into the kind of work you’ll be doing at Canva.
      Our first tip for applicants: be yourself! Canva celebrates individuality and all the diverse talents and experiences that people bring to our team.`,
    },
    {
      name: 'Jan Nowak',
      role: 'Project Manager',
      image: WorkStyle2,
      description: `Hiring is a two way conversation – you’re interviewing us as well.
      Throughout the process, you’ll get a real insight into the kind of work you’ll be doing at Canva.
      Our first tip for applicants: be yourself! Canva celebrates individuality and all the diverse talents and experiences that people bring to our team.`,
    },
    {
      name: 'Jan Nowak2',
      role: 'Project Manager',
      image: WorkStyle3,
      description: `Hiring is a two way conversation – you’re interviewing us as well.
      Throughout the process, you’ll get a real insight into the kind of work you’ll be doing at Canva.
      Our first tip for applicants: be yourself! Canva celebrates individuality and all the diverse talents and experiences that people bring to our team.`,
    },
  ],
  sectionOpenPositions: {
    title: 'Open Positions',
    description: `At Ragnarson we take the professional development of our team seriously and we always push to stay ahead of the curve.
    You will be exposed to the latest technologies, work on projects in a multitude of industries and develop valuable skills for years to come.`,
    noJobTitle: 'Can’t find a position that suits you?',
    noJobButtonText: 'Join our talent network',
  },
  sectionTeamCultures: {
    title: 'Team Culture',
    description: (
      <>
        <p>We are culture creators just as much as culture consumers.</p>
        <p>
          {`Ragnarson is a teal organization with autonomy and no managers.
          There is no corporate hierarchy or limits on how you want to grow.
          Our processes, documents, payroll, and financial records are internally transparent and accessible to all team members.
          We build our relationships on trust, so everyone contributes to important decisions based on open information and full context.
          Setting your own salary is just one example of how you can influence your career path here.`}
        </p>
        <p>
          {`From day one you become a part of the evolving company’s culture.
          As you grow, your cultural engagement shapes and creates your environment.
          Working at our self-managed organization enables you to act instead of just reacting.
          With all that comes great responsibility and need for constant self-development,
          but we believe that people empowered by the right opportunities are able to express themselves through their work.`}
        </p>
      </>
    ),
  },
};

export default dataTeam;
