import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getFontSet, getTypography, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { VikingWorld } from '@illustrations';

export const SectionMissonContainer = styled(Container)`
  position: relative;
  flex-direction: column-reverse;
  align-items: top;
  overflow: hidden;
  margin-top: ${spaceMb(5.5)};

  ${screenMin('lg')} {
    padding: 0;
    flex-direction: row;
    margin-top: ${spaceDt(10)};
  }
`;

export const SectionMissonVector = styled(VikingWorld)`
  width: ${toVW(478, 'mobile')};
  height: ${toVW(478, 'mobile')};
  max-width: ${toVW(478, 'mobile')};
  max-height: ${toVW(478, 'mobile')};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(-22.5)};
    margin-top: ${spaceMb(-32)};
  }

  ${screenMin('lg')} {
    width: ${toVW(737, 'desktop')};
    height: ${toVW(737, 'desktop')};
  }
`;

export const SectionContentWrapper = styled.div`
  ${screenMin('lg')} {
    margin-left: ${spaceDt(-30)};
    margin-right: ${spaceDt(13)};
    margin-top: ${spaceDt(4)};
  }

  ${screenMax('lg')} {
    width: ${'100%'};
    padding-left: ${spaceMb(13)};
    padding-right: ${spaceMb(4)};
  }
`;

export const SectionName = styled.p`
  margin: 0;
  ${getTypography('body-2')};
  color: ${color.text.secondary};

  ${screenMax('lg')} {
    margin-top: ${spaceMb(1)};
  }
`;

export const SectionTitle = styled.h3`
  ${getFontSet('heading-2', 'mobile')};
  width: ${toVW(223, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(915, 'desktop')};
    ${getFontSet('heading-3', 'desktop')};
  }
`;

export const SectionContent = styled.p`
  ${getTypography('body-1')};
  width: ${toVW(223, 'mobile')};
  margin-top: ${spaceMb(2.5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    width: ${toVW(810, 'desktop')};
    text-align: justify;
  }
`;
