import React from 'react';
import {
  SectionMissonContainer,
  SectionMissonVector,
  SectionContentWrapper,
  SectionName,
  SectionTitle,
  SectionContent,
} from './SectionMission.styled';
import dataTeam from '../Team.data';

export const SectionMission = () => {
  return (
    <SectionMissonContainer>
      <SectionMissonVector name="world" />
      <SectionContentWrapper>
        <div>
          <SectionName>{dataTeam.sectionMisson.name}</SectionName>
          <SectionTitle>{dataTeam.sectionMisson.title}</SectionTitle>
        </div>

        <SectionContent>{dataTeam.sectionMisson.description}</SectionContent>
      </SectionContentWrapper>
    </SectionMissonContainer>
  );
};
