import React from 'react';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { useStateScreenMobile } from '@helpers/hooks';
import {
  SectionPeople,
  SectionCoreValues,
  SectionHeader,
  SectionNumbers,
  SectionMission,
  SectionTeamCulture,
  SectionOpenPositions,
} from './sections';

const ContactFormHeadline = () => <ContactFormHeader>{useStateScreenMobile() ? '' : 'Apply'}</ContactFormHeader>;

const Team = () => (
  <>
    <SEO title="Team | Ragnarson" description="Meet the people who create Ragnarson" />
    <SectionHeader />

    <SectionPeople />

    <SectionNumbers />

    <SectionCoreValues />

    <SectionMission />

    <SectionTeamCulture />

    <SectionOpenPositions />

    <ContactForm headline={<ContactFormHeadline />} defaultSubject="Career" />
  </>
);

export default Team;
