import styled from 'styled-components';
import { Container } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding-left: ${spaceDt(3)};
  padding-right: ${spaceDt(3)};
  width: 100%;
  height: 100%;

  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SectionVector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${toVW(225, 'mobile')};

  ${screenMin('lg')} {
    height: ${toVW(478, 'desktop')};
  }

  .slide-vector {
    width: 100%;
    max-height: 100%;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  margin-left: ${spaceDt(3.5)};
  margin-right: ${spaceDt(3.5)};

  ${screenMax('lg')} {
    flex-direction: column;
    width: 100%;
  }
`;

export const SectionName = styled.p`
  margin: 0;
  ${getTypography('body-2')};
  color: ${color.text.secondary};

  ${screenMax('lg')} {
    margin-top: ${spaceMb(1)};
  }
`;

export const SectionTitle = styled.h2`
  ${getTypography('heading-2')};

  ${screenMax('lg')} {
    text-align: center;
  }
`;

export const SectionContent = styled.p`
  ${getTypography('body-2')};
  margin-top: ${spaceMb(2.5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    text-align: justify;
  }
`;

export const SectionContentWrapper = styled.div`
  width: 100%;
  ${screenMin('lg')} {
    margin-top: ${spaceDt(10)};
  }
`;
