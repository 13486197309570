import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

export const SectionOpenPositionsContainer = styled(Container)`
  position: relative;
  margin-top: ${spaceMb(9)};
  margin-bottom: ${spaceMb(6)};
  padding-left: 0;
  padding-right: 0;

  ${screenMin('lg')} {
    margin-top: ${spaceDt(12.5)};
    margin-bottom: ${spaceDt(14.5)};
  }
`;

export const SectionTitle = styled.h2`
  ${getTypography('heading-3')};
  width: ${toVW(190, 'mobile')};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-right: ${spaceMb(4)};
  }

  ${screenMin('lg')} {
    width: ${toVW(449, 'desktop')};
    margin-bottom: ${spaceDt(10)};
    margin-left: var(--content-padding-lg-left);
    margin-right: var(--content-padding-lg-right);
  }
`;

export const SectionContent = styled.p`
  ${getTypography('body-1')};
  width: ${toVW(296, 'mobile')};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-right: ${spaceMb(4)};
  }
`;

export const CantFintAnything = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaceMb(4)} var(--content-padding-right) ${spaceMb(2)} var(--content-padding-left);
  border-bottom: 1px solid ${color.text.primary};

  ${screenMin('lg')} {
    flex-direction: row;
    padding: ${spaceDt(6)} var(--content-padding-lg-right) ${spaceDt(6)} var(--content-padding-lg-left);
  }
`;

export const CantFindAnythingContent = styled.p`
  margin: 0;
  flex-grow: 1;
  ${getTypography('heading-3')}

  ${screenMax('lg')} {
    text-align: center;
    margin-bottom: ${spaceMb(3)};
  }
`;
