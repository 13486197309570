import React from 'react';
import PropTypes from 'prop-types';
import { NumberPadRectangle, NumberPadContainer, NumberContent, NumberDescription } from './NumberPad.styled';

export const NumberPad = ({ number, description, mobileHeight, mobileTop, desktopHeight, desktopTop }) => (
  <NumberPadContainer>
    <NumberContent>{number}</NumberContent>
    <NumberDescription>{description}</NumberDescription>
    <NumberPadRectangle
      mobileHeight={mobileHeight}
      mobileTop={Math.round(mobileTop / 8)}
      desktopHeight={desktopHeight}
      desktopTop={Math.round(desktopTop / 8)}
    />
  </NumberPadContainer>
);

NumberPad.propTypes = {
  /* eslint-disable react/require-default-props */
  number: PropTypes.string,
  description: PropTypes.string,
  mobileHeight: PropTypes.number,
  mobileTop: PropTypes.number,
  desktopHeight: PropTypes.number,
  desktopTop: PropTypes.number,
};
