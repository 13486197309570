import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useStateScreenMobile } from '@helpers/hooks';
import { Button } from '@fragments';
import { scrollToContactForm } from '@helpers/methods';
import {
  SectionOpenPositionsContainer,
  SectionTitle,
  SectionContent,
  CantFintAnything,
  CantFindAnythingContent,
} from './SectionOpenPositions.styled';
import { OpenPosition } from '../components';
import dataTeam from '../Team.data';

export const SectionOpenPositions = React.memo(() => {
  const isMobileScreen = useStateScreenMobile();
  const {
    allOpenPositionsJson: { openPositions },
  } = useStaticQuery(graphql`
    query {
      allOpenPositionsJson {
        openPositions: nodes {
          id
          name
          title
          description
          mainTechStack
          minSalary
          maxSalary
          location
          externalJobOfferLink
        }
      }
    }
  `);

  const jobList = useMemo(() => {
    return openPositions.map((item, index) => {
      const { externalJobOfferLink, title, mainTechStack, minSalary, maxSalary, location } = item;

      return (
        <OpenPosition
          name={title}
          details={mainTechStack}
          salaryMin={minSalary}
          salaryMax={maxSalary}
          location={location}
          url={externalJobOfferLink}
          key={index}
          isLast={index === openPositions.length - 1}
        />
      );
    });
  }, [openPositions]);

  return (
    <SectionOpenPositionsContainer id="open-positions">
      <SectionTitle>{dataTeam.sectionOpenPositions.title}</SectionTitle>
      {isMobileScreen && <SectionContent>{dataTeam.sectionOpenPositions.description}</SectionContent>}
      {jobList}

      <CantFintAnything>
        <CantFindAnythingContent>{dataTeam.sectionOpenPositions.noJobTitle}</CantFindAnythingContent>
        <Button onClick={scrollToContactForm}>{dataTeam.sectionOpenPositions.noJobButtonText}</Button>
      </CantFintAnything>
    </SectionOpenPositionsContainer>
  );
});
